<template>
  <div class="register-vue">
    <!--    Loader-->
    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        :color="'#00b8ee'"
        :is-full-page="true"
        blur="0"
      ></loading>
    </div>

    <Navigation
      :show-language-switcher="true"
      :show-user-menu="false"
      :showLogin="true"
    />

    <template>
      <div
        v-if="get_signup_data.step_meta"
        class="max-w-3xl mx-auto min-h-screen p-4"
      >
        <div class="mt-10 pb-10">
          <Form
            v-for="(step, index) in get_signup_data.step_meta"
            v-if="index === $store.getters.show_next_step"
            :id="index"
            :key="index"
            :demo-company="isDemoCompany"
            :demo-form="isDemoForm"
            :forms="get_signup_data.actions.GET.fields"
            :numberOfSteps="get_signup_data.step_meta.length - 1"
            :step="step"
            :tradingPlatforms="get_trading_platforms"
            classes="shadow rounded-md"
          />
        </div>
      </div>
    </template>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import Navigation from "@/components/Navigation";
import Form from "@/views/register/Form";
import { mapGetters } from "vuex";
import Cookie from "js-cookie";

export default {
  components: {
    Footer,
    Navigation,
    Form,
  },
  data() {
    return {
      isLoading: false,
      whitelabel: process.env.VUE_APP_WHITELABEL,
      data: "",
      EGMdemo: false,
      allowedDemoComapnies: ["ICMCapital", "GCCBrokers"],
      isDemoCompany: false,
      isDemoForm: false,
    };
  },
  methods: {
    addPasswordField() {
      const passwordFieldIndex =
        this.get_signup_data.step_meta[0][0].fields.findIndex(
          (field) => field === "password"
        );

      if (passwordFieldIndex !== -1) {
        this.get_signup_data.step_meta[0][0].fields.splice(
          passwordFieldIndex + 1,
          0,
          "password_confirmation"
        );
        this.get_signup_data.actions.GET.fields.splice(
          passwordFieldIndex + 1,
          0,
          {
            enabled: true,
            id: "password_confirmation",
            key: "password_confirmation",
            kind: "password",
            label: this.$t("password-confirmation"),
            needed: true,
            type: "string",
          }
        );
      }
    },
  },
  computed: {
    ...mapGetters(["get_trading_platforms", "get_demo_trading_platforms"]),

    get_signup_data() {
      let data = this.$store.getters.get_signup_data;

      return data;
    },

    lang() {
      return this.$store.getters.get_lang;
    },
  },
  watch: {
    lang() {
      this.$store.dispatch("set_demo_company_signup_data").then(() => {
        this.isLoading = false;
        const t = this.$t.bind(this);
        if (
          process.env.VUE_APP_WHITELABEL !== "TD365" &&
          this.get_signup_data
        ) {
          this.addPasswordField();
        }
      });
    },
  },
  beforeCreate() {
    if (!localStorage.getItem("step")) {
      localStorage.setItem("step", "0");
    }
  },
  created() {
    this.isLoading = true;

    if (this.allowedDemoComapnies.includes(this.whitelabel)) {
      this.isDemoCompany = true;
      this.isDemoForm = true;
    }

    if (this.$route.query.t && !Cookie.get("x-tracking-id")) {
      Cookie.set("x-tracking-id", this.$route.query.t);
    }
    if (Cookie.get("x-tracking-id")) {
      this.$store.dispatch(
        "ib_tracking_link_visited",
        Cookie.get("x-tracking-id")
      );
    }
    if (Cookie.get("x-tracking-id")) {
      this.$store
        .dispatch("display_trading_platforms_tracking_links")
        .then(() => {
          this.isLoading = false;
        });
    } else {
      this.$store.dispatch("display_trading_platforms").then(() => {
        this.isLoading = false;
      });
    }
    this.$store.dispatch("display_demo_trading_platforms");

    this.$store.dispatch("set_demo_company_signup_data").then(() => {
      this.isLoading = false;
      const t = this.$t.bind(this);
      if (
        (process.env.VUE_APP_WHITELABEL === "ICMCapital" &&
          this.get_signup_data) ||
        process.env.VUE_APP_WHITELABEL === "GCCBrokers"
      ) {
        this.addPasswordField();
      }
    });
  },
};
</script>
